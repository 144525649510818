import React, { useState, useCallback, lazy, Suspense } from 'react'
import { Breadcrumb } from '@ashleynexvelsolutions/breadcrumb'
import { InteriorHero } from '@ashleynexvelsolutions/interior-hero'
import { ResponsiveLayout } from '@ashleynexvelsolutions/responsive-layout'
import { Seo2 } from '@ashleynexvelsolutions/seo2'
import { ServiceDetails } from '@ashleynexvelsolutions/service-details'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import {
  useSeoData,
  PageSpecificSchemaFunction,
  ModalFunction,
  InteriorHeroFunction,
  BreadcrumbFunction,
  ServiceDetailsFunction,
  InteriorEstimateFunction,
  InteriorSection2Function,
  InteriorSection3Function,
  FaqFunction,
  ReasonsToChooseFunction,
  RecentBlogsFunction,
  XPinsFunction,
} from '../DataImports'

const PageSpecificSchema = lazy(() =>
  import('@ashleynexvelsolutions/page-specific-schema').then(module => ({
    default: module.PageSpecificSchema,
  })),
)

const Modal = lazy(() =>
  import('@ashleynexvelsolutions/modal').then(module => ({
    default: module.Modal,
  })),
)
const InteriorEstimate = lazy(() =>
  import('@ashleynexvelsolutions/interior-estimate').then(module => ({
    default: module.InteriorEstimate,
  })),
)
const InteriorSection = lazy(() =>
  import('@ashleynexvelsolutions/interior-section').then(module => ({
    default: module.InteriorSection,
  })),
)
const Faq = lazy(() =>
  import('@ashleynexvelsolutions/faq').then(module => ({
    default: module.Faq,
  })),
)
const ReasonsToChoose = lazy(() =>
  import('@ashleynexvelsolutions/reasons-to-choose').then(module => ({
    default: module.ReasonsToChoose,
  })),
)
const RecentBlogs = lazy(() =>
  import('@ashleynexvelsolutions/recent-blogs').then(module => ({
    default: module.RecentBlogs,
  })),
)

const XpinsV3 = lazy(() =>
  import('@ashleynexvelsolutions/xpins-v3').then(module => ({ default: module.XpinsV3 })),
)

// eslint-disable-next-line no-unused-vars
const Page = ({ data }) => {
  const [formModal, setFormModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const clickFunction = useCallback(() => setFormModal(true), [])
  const clickFunction2 = useCallback(() => setFormModal(false), [])

  return (
    <Layout>
      <InteriorHero {...InteriorHeroFunction(data)} onClick={clickFunction} />
      <ResponsiveLayout>
        <div className="py-16 page">
          <Breadcrumb {...BreadcrumbFunction(data)} />
          <ServiceDetails {...ServiceDetailsFunction(data)} />
          <Suspense fallback="loading">
            <InteriorEstimate {...InteriorEstimateFunction()} onClick={clickFunction} />
          </Suspense>
          {data.wpPage.interiorSection2.interiorSection2Content !== null && (
            <Suspense fallback="loading">
              <InteriorSection {...InteriorSection2Function(data)} />
            </Suspense>
          )}
          {data.wpPage.interiorSection3.interiorSection3Content !== null && (
            <Suspense fallback="loading">
              <InteriorSection {...InteriorSection3Function(data)} onClick={clickFunction} />
            </Suspense>
          )}

          {data.wpPage.nexvelschemapagesposts.questionsAndAnswers !== null && (
            <Suspense fallback="loading">
              <Faq {...FaqFunction(data)} />
            </Suspense>
          )}
        </div>
      </ResponsiveLayout>
      <Suspense fallback="loading">
        <ReasonsToChoose {...ReasonsToChooseFunction()} onClick={clickFunction} />
      </Suspense>
      <div className="rfs:pt-24">
        <Suspense fallback="loading">
          <XpinsV3 {...XPinsFunction(data)} />
        </Suspense>
      </div>
      <Suspense fallback="loading">
        <RecentBlogs {...RecentBlogsFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <Modal {...ModalFunction().modal} isDisplayed={formModal} notDisplayed={clickFunction2} />
      </Suspense>
      <Suspense fallback="loading">
        <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      </Suspense>
    </Layout>
  )
}
export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
      title
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      interiorHero {
        interiorHeroBackgroundImageAltText
        interiorHeroTitle
        interiorHeroSubtitle
      }
      breadcrumbs {
        breadcrumbsShortcode
      }
      interiorSection1 {
        interiorSection1Content
      }
      interiorSection2 {
        interiorSection2Content
      }
      interiorSection3 {
        interiorSection3Content
        interiorSection3Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        interiorSection3AltText
      }
      xPins {
        xPins {
          mapCenter {
            latitude
            longitude
          }
          radius
          pins {
            label
            date
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Page
export const Head = ({ data }) => <Seo2 {...useSeoData(data)} />
